import { useTheme } from "@emotion/react";
import type { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import type { AviaryAccents, AviaryColors } from "aviary-tokens";
import { colorProfileMapper } from "aviary-tokens";
import type { HTMLProps, ReactNode } from "react";

import { FontAwesomeIcon } from "@shared/react-fontawesome";

import * as styles from "./Pill.styles";

export type PillSizes = "xsmall" | "small" | "medium";

export type PillColors = Extract<
  AviaryColors,
  "success" | "system" | "highlight" | "danger" | "warning" | "info"
>;

interface Props extends Omit<HTMLProps<HTMLDivElement>, "size"> {
  /**
   * Determines the size of the pill
   *
   * @default "medium"
   */
  size?: PillSizes;
  /**
   * Shows a loading icon to the left of the content
   *
   * @default false
   */
  isLoading?: boolean;
  /**
   * Detemines the color profile of the pill.
   *
   * @default "success"
   */
  isColor?: PillColors;
  /**
   * Allows you to color the pill with an accent color
   *
   * @default null
   */
  accentColor?: AviaryAccents;
  /**
   * Turns the pill light to be used on dark backgrounds
   *
   * @default false
   */
  isLight?: boolean;
  /**
   * Unique id for e2e testing purposes
   */
  "data-e2e"?: string;
  /**
   * Unique id for testing purposes
   */
  "data-testid"?: string;
  /**
   * Toggle disabling the pill
   *
   * @default false
   */
  isDisabled?: boolean;
  /**
   * An icon to be rendered at the end of the dropdown item
   */
  icon?: IconDefinition;
  /**
   * Customizes the location of the icon
   *
   * @default start
   */
  iconLocation?: "start" | "end";
  /**
   * Pill content
   *
   * @default undefined
   */
  children?: ReactNode;
}

/**
 * Documentation:
 * https://aviary.docs.fullscript.cloud/display/Pill
 */
const Pill = ({
  isColor = "success",
  isLoading,
  size = "medium",
  isLight,
  icon,
  iconLocation = "start",
  children,
  isDisabled,
  accentColor,
  onClick,
  ...rest
}: Props) => {
  const currentTheme = useTheme();
  const themeColors = colorProfileMapper(currentTheme);

  const pillStyles = [
    styles.base,
    !isLight && isColor && styles.themedStyles(themeColors[isColor]),
    isLight && styles.lightPill,
    size && styles.size[size],
    isDisabled && styles.disabled,
    accentColor && styles.accentColor[accentColor],
  ];

  const iconStyles = () => [styles.icon[iconLocation]];

  const renderLoader = () => {
    return (
      isLoading && (
        <div css={styles.loader} data-testid="pill-loader">
          <FontAwesomeIcon icon={faSpinnerThird} spin />
        </div>
      )
    );
  };

  const renderIcon = (location: string) => {
    if (!icon || location !== iconLocation) return;

    return (
      <div css={iconStyles()} data-testid="pill-icon">
        <FontAwesomeIcon icon={icon} />
      </div>
    );
  };

  const isClickable = !isDisabled && typeof onClick === "function";
  const handleOnClick = e => isClickable && onClick(e);

  const handleKeyPressEvent = e => {
    if (e.key === "Enter" || e.key === " ") {
      handleOnClick(e);
    }
  };

  const a11yProps = {
    role: isClickable ? "button" : undefined,
    tabIndex: isClickable ? 0 : undefined,
    onKeyPress: handleKeyPressEvent,
  };

  return (
    <div css={pillStyles} onClick={handleOnClick} {...a11yProps} {...rest}>
      {renderLoader()}
      {renderIcon("start")}
      {children}
      {renderIcon("end")}
    </div>
  );
};

export type { Props as PillProps, PillColors as PillColorProps };
export { Pill };
