import { Global, useTheme } from "@emotion/react";
import { Tooltip as MUITooltip } from "@mui/material";
import type { ReactNode, HTMLProps } from "react";

import type { PopperPositionType } from "@aviary/types/popperPositions";
import { helpers, layers } from "@styles";

import * as styles from "./Tooltip.styles";

interface Props extends HTMLProps<HTMLDivElement> {
  /**
   * Conditionally hide the tooltip from being shown
   *
   * @default false
   */
  isTooltipHidden?: boolean;
  /**
   * Content that is shown within the tooltip when opened.
   */
  tooltipContent: ReactNode;
  /**
   * Conditionally hide the tooltip from being shown
   *
   * @default "right"
   */
  textDirection?: "left" | "right" | "center";
  /**
   * Where the tooltip appears in relation to the trigger content
   * Follows the PopperJS positions, can be found:
   * https://popper.js.org/docs/v2/constructors/#placement
   *
   * @default "top"
   */
  placement?: PopperPositionType;
  /**
   * Allows users to interact with the content in the tooltip, such as links
   *
   * @default true
   */
  interactive?: boolean;
  /**
   * Conditionally renders the tooltip next to the trigger in the DOM for keyboard focusability.
   * When false, MUI renders the tooltip in a new subtree outside of the DOM hierachy which causes the tab order to change:
   * https://mui.com/material-ui/react-popper/,
   * https://github.com/mui/material-ui/issues/33676#issuecomment-1198141186
   *
   * @default false
   */
  isPortalDisabled?: boolean;
  /**
   * Option to hide tooltip arrow
   *
   * @default false
   */
  hideArrow?: boolean;
  /**
   *Option to determine when the tooltip opens
   *
   * @default undefined
   */
  isOpen?: boolean;
  /**
   * The number of milliseconds to wait before showing the tooltip.
   * @default 100
   */
  enterDelay?: number;
  /**
   * The number of milliseconds a user must touch the element before showing the tooltip.
   * @default 700
   */
  enterTouchDelay?: number;
  /**
   * The number of milliseconds after the user stops touching an element before hiding the tooltip.
   * @default 1500
   */
  leaveTouchDelay?: number;

  /**
   * Content to render in the Tooltip
   *
   * @default undefined
   */
  children?: ReactNode;
}

/**
 * Documentation:
 * https://aviary.docs.fullscript.cloud/feedback/Tooltip
 */
const Tooltip = ({
  isTooltipHidden,
  textDirection = "right",
  tooltipContent,
  placement = "top",
  interactive = true,
  isPortalDisabled = false,
  hideArrow,
  isOpen,
  enterDelay,
  enterTouchDelay = 700,
  leaveTouchDelay = 1500,
  children,
  ...rest
}: Props) => {
  const tooltipStyles = [styles.wrapper, textDirection && styles.textDirection[textDirection]];
  const theme = useTheme();

  const renderTooltipContent = () => {
    return <>{tooltipContent}</>;
  };

  if (isTooltipHidden) {
    return <>{children}</>;
  }

  return (
    <>
      <Global styles={styles.tooltipOverrides} />
      <MUITooltip
        title={renderTooltipContent()}
        arrow={!hideArrow}
        placement={placement}
        disableInteractive={!interactive}
        leaveDelay={200}
        leaveTouchDelay={leaveTouchDelay}
        enterTouchDelay={enterTouchDelay}
        enterNextDelay={200}
        enterDelay={enterDelay}
        PopperProps={{ disablePortal: isPortalDisabled }}
        open={isOpen}
        componentsProps={{
          popper: {
            sx: { zIndex: layers.zIndexTopForeground },
          },
          arrow: {
            sx: { color: theme.inverted.backgroundBase },
          },
          tooltip: {
            sx: {
              backgroundColor: theme.inverted.backgroundBase,
              color: theme.inverted.textEmphasis,
              fontSize: theme.aviaryTypography.footnote.fontSize,
              padding: "0.5rem 0.75rem",
              boxShadow: `${helpers.hexToRgba(theme.surface.overlayBackdrop, 0.25)} 0 3px 8px 0`,
            },
          },
        }}
      >
        <div role="status" tabIndex={0} data-testid="trigger" css={tooltipStyles} {...rest}>
          {children}
        </div>
      </MUITooltip>
    </>
  );
};

export { Tooltip };
